$Grey: #333333;
$Gold: rgb(176, 124, 31);
$Green: #184a43;

*,
html,
body {
  font-family: "Poppins", "Roboto", sans-serif;
  color: $Grey;
}

.section {
  @media (min-width: 768px) {
    min-height: 100vh;
  }
}

.card {
  border: none;
  background: none;
}

.bg-grey {
  background-color: $Grey;
}

.bg-green {
  background-color: $Green;
}

.text-grey {
  color: $Grey !important;
}

.text-gold {
  color: $Gold !important;
}

.text-white {
  color: white !important;
}

input {
  border: none;
  border-bottom: 1px solid hsla(0, 0%, 20%, 0.146);
  color: $Grey;
  font-weight: 300;
  font-size: 0.8em;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $Grey;
  }
}

.footer-blend {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(transparent, white);
  pointer-events: none;

  @media (min-width: 768px) {
    height: 200px;
  }
}
