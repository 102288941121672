#footer {
  font-size: 0.8em;
  font-weight: 400;

  #footer-contact {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid $Grey;
    padding: 5px 15px;
    text-align: end;
    transition: all 0.15s ease-in-out;

    &:hover {
      border-color: $Green;
      color: $Green;
      transform: scale(1.05);
    }
  }

  #footer-pages,
  #footer-links {
    a {
      color: inherit;
      text-decoration: none;
      display: flex;
      align-items: center;
      i {
        margin-right: 10px;
      }

      &:hover {
        transform: none;
      }
    }
  }

  #footer-pages {
    a {
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(1.05);
        color: $Green;

        i {
          color: $Green;
        }
      }
    }
  }

  form {
    margin-top: 1em;

    &.border-bot-white {
      input {
        border-color: white;
        color: white;
        background: none;

        &::placeholder {
          color: white;
        }
      }

      i {
        color: white;
      }
    }

    button {
      background: none;
      border: none;
    }
  }

  #footer-links {
    p,
    a {
      letter-spacing: 1px;
      margin: 0;
      font-size: 0.8em;
    }

    a {
      color: $Grey;
      text-decoration: none;
      transition: all 0.15s ease-in-out;

      &#nunu-link {
        border: none !important;
        &:hover {
          color: $Grey;
          span {
            border-bottom: 1px solid $Green;
            color: $Green;
          }
        }
      }

      &:hover {
        color: $Green;
        transform: scale(1.025);
        border-bottom: 1px solid $Green;
      }
    }
  }
}
