.nav-item {
  letter-spacing: 3px;
  font-size: 0.8rem;
  color: white;
  transition: all 0.2s ease-in-out;

  @media (min-width: 768px) {
    margin: 0 15px;
  }

  &:hover {
    color: white;
    transform: scale(1.05);
  }

  &.active {
    color: $Gold !important;
    border-bottom: 1px solid $Gold;
  }
}

.navbar-brand {
  transition: all 0.2s ease-in-out;

  p {
    letter-spacing: 3px;
    font-size: 0.8rem;
  }

  &:hover {
    transform: scale(0.98);
  }
}

#navbar-container {
  z-index: 999;
}

#navbar:not(.light) .navbar-toggler {
  border-color: hsl(0, 0%, 50%);
}

#navbar.light .navbar-toggler {
  border-color: $Grey;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23333333' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important; /* Changes the icon to grey */
  }
}

.navbar-toggler {
  transition: all 0.15s ease-in-out;

  &:focus,
  &:active {
    box-shadow: none;
  }
}

.light .custom-dropdown .dropdown-toggle::after {
  color: $Grey !important;
}

.custom-dropdown .dropdown-toggle::after {
  color: white !important;
}

.dropdown-menu {
  letter-spacing: normal;

  .dropdown-item {
    font-weight: 300;

    &.active {
      background-color: $Gold;
      color: white;
    }

    &:hover {
      background-color: hsla(172, 51%, 19%, 0.1);
    }
  }
}

.custom-dropdown {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;

  &.active {
    a {
      &::after {
        color: $Gold !important;
      }
    }
    span {
      color: $Gold !important;
    }
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important; /* Changes the icon to white */
}
