#listings-component {
  h1 {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }

  select {
    border-bottom: 1px solid hsla(0, 0%, 20%, 0.146);
    font-weight: 300;
    font-size: 0.8em;
    padding: 6px;
    &.form-select {
      --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23333333%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    }
  }

  .card {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }

    .card-title {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1em;
    }

    img {
      aspect-ratio: 1 / 0.9;
      object-fit: cover;
    }

    .card-text {
      letter-spacing: 2px;
      font-size: 0.8em;
      font-weight: 300;
      margin: 10px 0;

      &.fw-bold {
        letter-spacing: 1px;
      }
    }
  }
}
