#home {
  h1 {
    color: white;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    right: 0;
  }

  #scroll-toggle {
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #video-toggle {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  #video-toggle,
  #scroll-toggle {
    position: absolute;
    background: none;
    border: none;
    font-size: 1.5em;

    i {
      color: white;
      padding: 5px;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      i {
        transform: scale(1.15);
      }
    }
  }

  h1 {
    letter-spacing: 5px;
    font-size: calc(1.8rem + 1.5vw);
    font-weight: 500;
  }

  #landing {
    p {
      letter-spacing: 5px;
    }
  }
}
