#contact {
  #contact-team {
    .row {
      margin: 0 5em;

      @media (max-width: 768px) {
        margin: 0 2em;

        .col:first-child {
          margin-bottom: 2em;
        }
      }
    }

    img {
      aspect-ratio: 1 / 1.5;
      object-fit: cover;
      &:nth-child(2) {
        fill: 120%;
      }
    }

    h2 {
      font-size: 1em;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
      margin-top: 1em;
    }

    p {
      font-size: 0.8em;
      font-weight: 300;
      letter-spacing: 3px;
      margin: 0;
      color: $Gold;
    }

    a {
      color: $Grey;
      text-decoration: none;
      font-size: 0.8em;
      font-weight: 300;
      letter-spacing: 3px;
      transition: all 0.15s ease-in-out;

      &:hover {
        text-decoration: underline;
        color: $Green;
      }
    }
  }

  #contact-form {
    margin-top: 5em;

    button {
      transition: all 0.15s ease-in-out;
      padding: 0 10px;

      &:hover {
        color: $Gold;
        border-color: $Gold;
        transform: scale(1.02);

        i {
          color: $Gold;
        }
      }
    }

    form {
      div {
        margin: 2em 0;

        label {
          text-transform: uppercase;
        }

        input {
          font-size: 1em;
        }

        textarea {
          border-radius: 5px;
          border-color: hsla(0, 0%, 20%, 0.146);
          padding: 0.4em;
          font-weight: 300;
        }
      }
    }
  }

  button[type="submit"] {
    background: none;
    border: none;
    border-bottom: 1px solid $Grey;
    border-radius: 0;
    color: $Grey;
    padding: 8px 20px;
    text-transform: uppercase;
    font-size: 1.2em;
    margin-top: 2em;
    padding: 0;
    display: flex;
    align-items: center;
    i {
      font-size: 0.8em;
    }
  }
}
